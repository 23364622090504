import React from "react"
import { Link } from "gatsby"
export const ServiceBox = ({ name, text, icon, to }) => {
  return (
    
     <Link to={to} className="service-box">
      <div className={`img-box ${icon}`} />
      <div className="content">
        <h4>{name}</h4>
        <p>{text}</p>
        <p className="box-label line-below">{to && 'Learn More'}</p>
        </div>
     </Link>

  )
}

export const service_data = [
  {
    name: "Touchstone",
    text:
      "We offer Touchsone, a test suite manager packaged with NFVI benchmarking, certification and validation tools that tests your cloud infrastructure environments against performance, functionality, robustness and stability test cases.",
    to: "/touchstone",
    icon: "icon-dash",
  },
  {
    name: "Edge Cloud",
    text:
      "We are driving the definitions of industry standard open APIs & KPIs requirements for interoperability in edge cloud infrastructure. We are focused around test automation for a reliable, secure and stable edge cloud infrastructure.",
    to: "/edge-cloud",
    icon: "icon-edge",
  },
  {
    name: "NFVI Certification",
    text:
      "We perform open-source certifications based upon the official test suites from OPNFV & OpenStack. Also we offer an extended certification by utilizing some 200 industry benchmark KPIs, for operation readiness validation, through robustness & stability testing",
    icon: "icon-cert",
  },
  {
    name: "Education & Consulting",
    text:
      "We conduct courses and workshops to get started with NFVI and our tool - Touchstone. We also help organizations sort structural and technical issues around NFV transformation and consult from procurement to life cycle management.",
    to: "/education-consultation",
    icon: "icon-edu",
  },
  {
    name: "Cloud Build/DevOps Tools",
    text:
      "We offer end-to-end Cloud & DevOps solutions for organizations, packaged with tools to build & deploy over a variety of resources, with security & reliability for the end-user. We also provide consultation, continuous integration, deployment & delivery.",
    to: "/cloud-build-devops-tools",
    icon: "icon-devops",
  },
]
