import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { ServiceBox, service_data } from "../components/ServiceBox"
import BackgroundHeader from "../components/background/BackgroundHeader"
import BackgroundSection2 from "../components/background/BackgroundSection2"
import ContactUs from "../components/ContactUs"
import { news_data_2021 } from "../components/NewsBox"

class NewsBar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      current: 1,
      animation: true,
      hover: false,
      news: [...news_data_2021],
      turn: true,
      isCancelled: false
    }
      this.timer = setInterval(() => {
        if (!this.state.hover) {
          !this.state.isCancelled && this.setState({ turn: !this.state.turn })
        }
      }, 3000)
  }
  componentWillUnmount = () => {
    this.state.isCancelled = true;
    clearInterval(this.timer)
  }
  render() {
    let news = [...news_data_2021]
    return (
      <div
        className="news-bar news-bar-box"
        onMouseOut={() => this.setState({ hover: false })}
        onMouseOver={() => this.setState({ hover: true })}
      >
        <h2>Latest News &amp; Updates</h2>

        {this.state.turn && (
          <div className="line-up">
            <p>{news[0].heading}</p>
            <Link to="/news" className="label-text line-below">
              Learn More
            </Link>
          </div>
        )}
        {!this.state.turn && (
          <div className="line-up">
            <p>{news[1].heading}</p>
            <Link to="/news" className="label-text line-below">
              Learn More
            </Link>
          </div>
        )}
      </div>
    )
  }
}

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <section className="section-main">
      <div className="background-row-wrapper">
        <div className="inner-wrap">
          <BackgroundHeader />
        </div>
        <div className="row-bg-overlay" />
      </div>
      <div className="main-box">
        <h1>
          Making Transformation to <span>Cloud Successful</span>
        </h1>
        <p>
          We bring down the risk and cost in transformation to cloud infrastructure
        </p>
      </div>
      <NewsBar />
    </section>
    <section className="box-one-theme mb-5">
      <div className="container">
        <div className="row align-items-end">
          <div className="col-lg-6">
            <h2 className="heading-2 text-white">
            Efficient cloud systems need testing to optimize Performance and Cost
            </h2>
            <p className="main-para text-white">
              {/*Our team can work together to help you transform to cloud*/}
            </p>
          </div>
          <div className="col-lg-6">
          <p className="main-para text-white">
          Our dedicated team of experts excels in uncovering and resolving hidden network complexities, 
          guaranteeing the attainment of a cost-effective, efficient, and optimized infrastructure.
            </p>
            <p className="main-para text-white mb-2">
            With a seasoned group of professionals well-versed in OpenStack, Kubernetes, NFV, 
            telecom applications, and VNF/CNF onboarding, we have an extensive range of expertise 
            at your disposal. They are fully prepared to provide guidance and support throughout 
            every phase of your transformation journey.
            </p>
            
          </div>
        </div>
      </div>
    </section>
    <section className="section-padding-top">
      <div className="container">
        <div className="row">
          <div className="col-lg-9">
            <div className="col-limit-3">
              <div className="heading-top"></div>
              <h2 className="heading-2 mb-5">We have great values to offer</h2>
            </div>
            <p className="paragraph-2">
              We offer tools to test, benchmark and certify your physical and
              virtual networks, making it feasible for you to deploy your
              infrastructure to the cloud with reliability.
            </p>
            <p className="paragraph-2">
              Organisations leverage from our solutions to enhance the security
              and reliability of their infrastructure.
            </p>
          </div>
          {service_data.map((o, i) => {
            return (
              <div key={i} className="col-lg-3 mt-4">
                <ServiceBox {...o} />
              </div>
            )
          })}
        </div>
      </div>
    </section>
    <section className="section-img">
      <div className="container">
        <div className="background-row-wrapper left less-width">
          <div className="inner-wrap box-top">
            <BackgroundSection2 />
          </div>
          <div className="row-bg-overlay" />
        </div>
        <div className="row img-row-padding">
          <div className="offset-lg-6 col-lg-6">
            <div className="col-limit-3">
              <div className="heading-top"></div>
              <h2 className="heading-2">Benefits you get with us</h2>
            </div>
            <div className="mt-5 mb-2">
              <h3 className="heading-4">
                Analyse your cloud characteristics with us
              </h3>

              <p className="paragraph-2 mb-2">
                Our system benchmarking solutions provide you with deeper
                understanding of the performance characteristics of your cloud
                infrastructure, along with the insights of our professional team
                gathered through years of experience across the industry.
              </p>
            </div>
            <div className="mt-5 mb-2">
              <h3 className="heading-4">Solve issues with our experts</h3>

              <p className="paragraph-2 mb-2">
                Our Subject Matter Experts have been providing their consultancy
                to several tier 1 companies. You can take the benefit from our
                skilled experts in areas like NFVI challenges, Cloud
                deployments, Cloud testing and DevOps security.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <ContactUs />
  </Layout>
)

export default IndexPage
